<template>
  <div class="relative mb-8 pt-4 pb-4">
    <hr class="border-slate-300" />
    <div class="absolute top-0 left-0 flex w-full justify-around">
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="relative flex flex-col items-center"
      >
        <div
          class="rounded-md px-4 py-1.5 text-sm font-semibold"
          :class="{
            border: index != currentStep,
            'border-slate-300': index != currentStep,
            'bg-white': index != currentStep,
            'text-slate-500':
              index != currentStep || company.primaryText != 'light',
            'text-white':
              index == currentStep || company.primaryText == 'light',
          }"
          :style="{
            backgroundColor: index == currentStep ? company.primary[500] : null,
          }"
        >
          {{ index + 1 }}
        </div>
        <div
          class="absolute top-full whitespace-nowrap py-1.5 text-sm font-semibold"
        >
          {{ t(`steps.${step.label}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  props: {
    currentStep: {
      required: true,
      type: Number,
    },
    steps: {
      required: true,
      type: Array[Object],
    },
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    return {
      company: computed(() => store.state.company.company),
      t,
    };
  },
};
</script>
