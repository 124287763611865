<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{
        route.path.includes("documents")
          ? t("headings.create_document")
          : t("headings.create_template")
      }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card p-4">
      <div class="p-4">
        <form-steps :currentStep="currentStep" :steps="steps" />
      </div>
      <div v-if="currentStep == 0">
        <div
          class="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        >
          <div
            class="flex cursor-pointer items-center justify-center rounded-md border border-slate-300 py-20 text-sm font-semibold"
            :class="{
              'text-slate-500':
                selectedTemplate != 'empty' || company.primaryText != 'light',
              'text-white':
                selectedTemplate == 'empty' && company.primaryText == 'light',
            }"
            :style="{
              backgroundColor:
                selectedTemplate == 'empty' ? company.primary[500] : null,
              borderColor: hovers[0] ? company.primary[500] : null,
            }"
            @click="selectTemplate('empty')"
            @mouseover="hovers[0] = true"
            @mouseleave="hovers[0] = false"
          >
            {{ t("labels.empty_document") }}
          </div>
          <div
            v-for="(template, index) in templates"
            :key="template.id"
            class="flex cursor-pointer items-center justify-center rounded-md border border-slate-300 py-20 text-sm font-semibold"
            :class="{
              'text-slate-500':
                selectedTemplate != template || company.primaryText != 'light',
              'text-white':
                selectedTemplate == template && company.primaryText == 'light',
            }"
            :style="{
              backgroundColor:
                selectedTemplate == template ? company.primary[500] : null,
              borderColor: hovers[index + 1] ? company.primary[500] : null,
            }"
            @click="selectTemplate(template)"
            @mouseover="hovers[index + 1] = true"
            @mouseleave="hovers[index + 1] = false"
          >
            {{ template.name }}
          </div>
        </div>
        <hr class="my-3 border-gray-300" />
        <div class="flex justify-end">
          <form-button
            :command="() => nextStep()"
            :disabled="waiting || !selectedTemplate"
            id="submit-button"
            label="next"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
      </div>
      <div v-else-if="currentStep == 1">
        <div class="mb-3 grid grid-cols-1 gap-3 md:grid-cols-3">
          <div class="w-full">
            <label>{{ t("labels.document_name") }}</label>
            <form-input
              class="w-full"
              id="document-name-input"
              :invalid="state.name.error ? true : false"
              type="text"
              v-model="state.name.value"
            />
          </div>
          <div class="w-full">
            <label>{{ t("labels.document_type") }}</label>
            <form-select
              id="document-type-select"
              :invalid="state.classificationId.error ? true : false"
              :optionLabel="['label']"
              :options="classifications"
              translatableLabel="classifications"
              v-model="state.classificationId.value"
            />
          </div>
          <div class="w-full">
            <label>{{ t("labels.estate_name") }}</label>
            <form-select
              id="document-estate-select"
              :invalid="state.estateId.error ? true : false"
              :optionLabel="['name']"
              :options="estates"
              v-model="state.estateId.value"
            />
          </div>
        </div>
        <editor v-model="state.content.value" />
        <hr class="my-3 border-gray-300" />
        <div class="flex justify-between">
          <form-button
            :command="() => previousStep()"
            :disabled="waiting"
            id="previous-button"
            label="previous"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
          <div class="flex gap-3">
            <form-button
              :command="() => saveTemplate()"
              :disabled="waiting"
              id="save-template-button"
              label="save_as_template"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
            />
            <form-button
              :command="() => save()"
              :disabled="waiting"
              id="save-button"
              label="save"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
            />
            <form-button
              :command="() => nextStep()"
              :disabled="waiting"
              id="next-button"
              label="next"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div>
          <h3>{{ t("headings.signatories") }}</h3>
          <div class="flex w-full flex-col gap-4 lg:w-2/3 xl:w-1/2">
            <div
              v-for="(_, sIndex) in signatories"
              :key="sIndex"
              class="flex gap-4"
            >
              <form-input
                class="w-full"
                id="signatory-input"
                :placeholder="t('labels.email')"
                type="email"
                v-model="signatories[sIndex]"
              />
              <form-button
                v-if="signatories.length > 1"
                :command="() => removeSignatory(sIndex)"
                :icon="TrashIcon"
                id="signatory-remove-button"
                label=""
                textVariant="light"
                type="button"
                variant="danger"
              />
            </div>
            <small v-if="signatoryError" class="text-rose-600">{{
              t(`form.errors.${signatoryError}`)
            }}</small>
            <div class="flex justify-end gap-2">
              <form-button
                :command="() => addBoardMembers()"
                :disabled="waiting"
                id="add-board-members-button"
                label="add_board_members"
                :textVariant="company.primaryText"
                type="button"
                :variant="company.primary"
              />
              <form-button
                :command="() => addManagers()"
                :disabled="waiting"
                id="add-managers-button"
                label="add_managers"
                :textVariant="company.primaryText"
                type="button"
                :variant="company.primary"
              />
              <form-button
                :command="() => addSignatory()"
                :disabled="waiting"
                id="add-signatory-button"
                label="add_signatory"
                :textVariant="company.primaryText"
                type="button"
                :variant="company.primary"
              />
            </div>
          </div>
        </div>
        <hr class="my-3 border-gray-300" />
        <div class="flex justify-between">
          <form-button
            :command="() => previousStep()"
            :disabled="waiting"
            id="previous-button"
            label="previous"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
          <form-button
            :command="() => saveAndSend()"
            :disabled="waiting"
            id="send-button"
            label="save_and_send"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TrashIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import Editor from "../components/editor/Editor.vue";
import FormButton from "../components/form/FormButton.vue";
import FormInput from "../components/form/FormInput.vue";
import FormSelect from "../components/form/FormSelect.vue";
import FormSteps from "../components/form/FormSteps.vue";
import validate from "../utils/validators";
export default {
  components: {
    Breadcrumb,
    Editor,
    FormButton,
    FormInput,
    FormSelect,
    FormSteps,
    TrashIcon,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();

    const boardMembers = computed(() => store.state.user.boardMembers);
    const classifications = computed(
      () => store.state.document.classifications
    );
    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const personnel = computed(() => store.state.user.personnel);
    const templates = computed(() => store.state.document.templates);

    const waiting = computed(() => {
      if (store.state.document.waiting) return true;
      if (store.state.estate.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const state = reactive({
      classificationId: {
        error: null,
        rules: ["isRequired"],
        value: null,
      },
      content: {
        error: null,
        rules: [],
        value: "",
      },
      estateId: {
        error: null,
        rules: ["isRequired"],
        value: null,
      },
      name: {
        error: null,
        rules: ["isRequired"],
        value: "",
      },
    });

    const selectedTemplate = ref(null);

    const selectTemplate = (template) => {
      selectedTemplate.value = template;
    };

    const currentStep = ref(0);
    const steps = [
      { label: "template" },
      { label: "editor" },
      { label: "send" },
    ];

    const nextStep = async () => {
      if (currentStep.value == 0) {
        setState();
      } else if (currentStep.value == 1) {
        const isValid = await isFormValid();
        if (!isValid) return;
      }
      currentStep.value++;
    };

    const previousStep = () => {
      currentStep.value--;
    };

    const hovers = ref([]);

    const setState = () => {
      if (selectedTemplate.value == "empty") {
        state.classificationId.value = null;
        state.content.value = "";
      } else {
        state.classificationId.value = selectedTemplate.value.classificationId;
        state.content.value = JSON.parse(selectedTemplate.value.content);
      }
    };

    const signatories = ref([""]);
    const signatoryError = ref(null);

    const addBoardMembers = () => {
      store.dispatch("user/getBoardMembers", document.value.estateId);
    };

    const addManagers = () => {
      store.dispatch("user/getPersonnel", document.value.companyId);
    };

    const addSignatory = () => {
      signatories.value.push("");
    };

    const removeSignatory = (index) => {
      signatories.value.splice(index, 1);
    };

    const isFormValid = async (isValid = true) => {
      for (const [_, value] of Object.entries(state)) {
        value.error = await validate(value.rules, value.value);
        if (value.error) isValid = false;
      }
      return isValid;
    };

    const saveAndSend = async () => {
      for (const signatory of signatories.value) {
        signatoryError.value = await validate(
          ["isRequired", "isEmail"],
          signatory
        );
      }
      if (signatoryError.value) return;
      const data = {
        classificationId: state.classificationId.value,
        companyId: company.value.id,
        content: JSON.stringify(state.content.value),
        estateId: state.estateId.value,
        name: state.name.value,
        signatories: signatories.value,
      };
      store.dispatch("document/createDocument", data);
    };

    const save = async () => {
      const isValid = await isFormValid();
      if (!isValid) return;
      const data = {
        classificationId: state.classificationId.value,
        companyId: company.value.id,
        content: JSON.stringify(state.content.value),
        estateId: state.estateId.value,
        name: state.name.value,
      };
      store.dispatch("document/createDocument", data);
    };

    const saveTemplate = async () => {
      const isValid = await isFormValid();
      if (!isValid) return;
      const data = {
        classificationId: state.classificationId.value,
        companyId: company.value.id,
        content: JSON.stringify(state.content.value),
        estateId: state.estateId.value,
        name: state.name.value,
      };
      store.dispatch("document/createTemplate", data);
    };

    onMounted(() => {
      if (!classifications.value.length) {
        store.dispatch("document/getClassifications");
      }
      if (!estates.value.length) {
        store.dispatch("estate/getEstates", company.value.id);
      }
      if (!templates.value.length) {
        store.dispatch("document/getTemplates", company.value.id);
      }
    });

    watch(
      () => boardMembers.value,
      (val) => {
        for (const boardMember of val) {
          if (!signatories.value.includes(boardMember.email)) {
            signatories.value.push(boardMember.email);
          }
        }
      }
    );

    watch(
      () => personnel.value,
      (val) => {
        for (const manager of val) {
          if (!signatories.value.includes(manager.email)) {
            signatories.value.push(manager.email);
          }
        }
      }
    );

    return {
      addBoardMembers,
      addManagers,
      addSignatory,
      classifications,
      company,
      currentStep,
      estates,
      hovers,
      nextStep,
      previousStep,
      removeSignatory,
      route,
      saveAndSend,
      save,
      saveTemplate,
      selectedTemplate,
      selectTemplate,
      signatories,
      signatoryError,
      state,
      steps,
      t,
      templates,
      TrashIcon,
      waiting,
    };
  },
};
</script>
